
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockSocialLinks",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		blockHeadline() {
			if (!this.settings?.["headline-show"]) return null;
			return this.settings.headline || null;
		},
		blockUsername() {
			if (!this.settings?.["username-show"]) return "";
			return this.settings.username || null;
		},
		blockFacebook() {
			if (this.settings?.["facebook-show"] === false) return "";
			return renderData(this.settings.facebook, this.dataSite);
		},
		blockLinkedin() {
			if (this.settings?.["linkedin-show"] === false) return "";
			return renderData(this.settings.linkedin, this.dataSite);
		},
		blockInstagram() {
			return this.settings.instagram || "";
		},
		blockTwitter() {
			if (this.settings?.["twitter-show"] === false) return "";
			return renderData(this.settings.twitter, this.dataSite);
		},
		blockYoutube() {
			return this.settings.youtube || "";
		},
	},
};
